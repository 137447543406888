export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>Benefits of Kausal</h2>
        </div>
        <div>
          {/* Replace this with your static image */}
          <div className="pdf-container">
            <img
              src="pdf/kausal_page-0001.jpg" // Update this path to your actual image
              alt="Kausal Benefits"
            />
            <a
              href="/pdf/kausal.pdf"
              download="kausal.pdf"
            >
              Download PDF
            </a>
          </div>
        </div>
        <div className="about-content">
          <div className="about-text">
            <h3>What makes Kausal Different?</h3>
            <div>
              <ul>
                {props.data
                  ? props.data.Why.map((d, i) => (
                    <li key={`${d}-${i}`}>{d}</li>
                  ))
                  : "loading"}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};